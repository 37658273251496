import React from 'react';
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'

import IconFacebook from '../../images/svgs/facebook-icon.svg'
import IconInstagram from '../../images/svgs/instagram-icon.svg'
import IconLinkedin from '../../images/svgs/linkedin-icon.svg'
import IconTwitter from '../../images/svgs/twitter-icon.svg'

export default function Sponsor( props ) {

	const { trailYear, sponsor, sculptureSlug, sculptureName, index } = props
	const { sponsorWebsite, sponsorName, sponsorDescription, sponsorSocial, sponsorImage } = sponsor

	return (
		<div className={`info-list__item ${ index % 2 ? 'info-list__item--alt' : ''}`}>
			<div className="info-list__image-container">
				{ sponsorImage?.imageFile?.childImageSharp?.fluid &&
					<Img className="info-list__image" fluid={ sponsorImage.imageFile.childImageSharp.fluid } imgStyle={{ objectFit: "contain" }} />
				}
			</div>

			<div className="info-list__info">
				<h3 className="info-list__heading">{ sponsorName }</h3>
				<ReactMarkdown
					className="info-list__description"
					children={ sponsorDescription }
				/>
				<div className={`info-list__social-list ${ !sponsorSocial.length ? 'info-list__social-list--no-social' : '' }`}>
					{ sponsorWebsite &&
						<a href={ sponsorWebsite } className="info-list__link" target="_blank" rel="noreferrer">
							Visit Website
						</a>
					}
					{ !!sponsorSocial.length &&
						<div className="info-list__social-icons">
							{ sponsorSocial.map( social => (
								<a href={ social.socialLink } className="info-list__icon-link" target="_blank" rel="noreferrer" key={ social.id }>
									{ social.social === "Instagram" && <IconInstagram className="info-list__icon" /> }
									{ social.social === "Facebook" && <IconFacebook className="info-list__icon" /> }
									{ social.social === "LinkedIn" && <IconLinkedin className="info-list__icon" /> }
									{ social.social === "Twitter" && <IconTwitter className="info-list__icon" /> }
								</a>
							) ) }
						</div>
					}
					{ sculptureName &&
						<span className="info-list__alt-link-container">
							Sponsored Sculpture:
							<Link to={`${ trailYear ? `/${ trailYear }` : '' }/sculptures/${ sculptureSlug }`} className="info-list__link">
								{ sculptureName }
							</Link>
						</span>
					}
				</div>
			</div>
		</div>
	)
}
