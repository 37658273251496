import React, { useState, useEffect } from 'react'

import Sponsor from './Sponsor'

export default function SponsorsList( props ) {

	const { sponsors, trailYear } = props
	const [ sponsorWithSculpture, setSponsorWithSculpture ] = useState( [] )
	const [ sponsorNoSculpture, setSponsorNoSculpture ] = useState( [] )

	useEffect(() => {

		sponsors.forEach( sponsor => {
			if ( sponsor.sculpture ) {
				setSponsorWithSculpture( state => ( [
					...state,
					sponsor
				] ) )
			} else {
				setSponsorNoSculpture( state => ( [
					...state,
					sponsor
				] ) )
			}
		} )

	}, [ sponsors, setSponsorWithSculpture, setSponsorNoSculpture ])

	return (
		<div className="info-list wrapper">
			{ sponsorWithSculpture.map( ( sponsor, index ) => (
				<div className="info-list__container" key={ sponsor.id }>
					<Sponsor
						sponsor={ sponsor }
						index={ index }
						sculptureName={ sponsor.sculpture.sculptureName }
						sculptureSlug={ sponsor.sculpture.sculptureSlug }
						trailYear={ trailYear } />
				</div>
			) ) }

			{ sponsorNoSculpture.map( ( sponsor, index ) => (
				<div className="info-list__container" key={ sponsor.id }>
					<Sponsor
						sponsor={ sponsor }
						index={ sponsorWithSculpture.length % 2 ? index + 1 : index }
						trailYear={ trailYear } />
				</div>
			) ) }
		</div>
	)
}
