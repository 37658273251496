import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import SponsorsList from '../components/SponsorsList/SponsorsList'
import PageHeader from '../components/PageHeader/PageHeader'

const sponsorsTemplate = ( { data: { strapi: { sponsors } }, location, pageContext } ) => {

	const trailYear = sponsors.length ? sponsors[ 0 ].artTrail.trailYear : ''

	return (
		<>
			<SEO title={ `Sponsors ${ trailYear }` } pathname={ location.pathname } />

			<PageHeader heading="Sponsors" />

			<SponsorsList sponsors={ sponsors } trailYear={ pageContext.index ? pageContext.trailYear : ''} />
		</>
	)
}

export default sponsorsTemplate

export const query = graphql`
	query sponsorsQuery($id: ID!) {
		strapi {
			sponsors( where: { artTrail: $id }, sort: "sponsorName:ASC" ) {
				id
				sponsorName
				sponsorWebsite
				sponsorDescription
				sponsorSocial {
					id
					social
					socialLink
				}
				sponsorImage {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxHeight: 1000) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
				artTrail {
					trailYear
				}
			}
		}
	}
`